<template>
  <div class="about_us_Lay_out">
    <!-- 顶部 -->
    <div class="top_advBox">
      <TopAdv />
    </div>
    <!-- 关于我们 -->
    <div class="about_us_box">
      <AboutUs />
    </div>
    <!-- 底部 -->
    <BottomIntroduce />
  </div>
</template>

<script>
import TopAdv from "../../components/TopAdv";
import BottomIntroduce from "../../components/BottomIntroduce";
import AboutUs from "./components/AboutUs";
export default {
  name: "aboutUsLayOut",
  components: {
    TopAdv,
    BottomIntroduce,
    AboutUs,
  },
  created() {
    this.$store.commit("modifyVuexAdvActive", { vuexAdvActive: 4 });
  },
};
</script>
<style scoped >
.about_us_Lay_out {
  min-width: 1200px;
}
.about_us_box{
  margin-top: 75px;
}
</style>
